import React, { createContext, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import ProductDetail from './ProductDetail';
import ModularBlocks from './ModularBlocks';
import ProductListing from './ProductListing';
import ImageContent from './ImageContent';
import {
  checkIfMural,
  getShopifyLocaleFields,
  translateString,
} from '../utils';
import { StoreContext } from '../context/StoreContext';
import HowMuralsWork from './HowMuralsWork';
import ProductCardSlider from './ProductCardSlider';
import ProductReviews from './ProductReviews';

export const ProductContext = createContext({
  selectedVariant: '',
  setSelectedVariant: () => {},
  selectedSampleVariant: '',
  setSelectedSampleVariant: () => {},
  isPaperSamplesOpen: false,
  togglePaperSamplesOpen: () => {},
});

const Product = ({
  shopifyProduct,
  allShopifyProductVariant: { nodes: variants },
  allShopifySampleProductVariant: { nodes: sampleVariants },
  datoCmsProduct: {
    locale,
    mainImages,
    thumbnailImages,
    reviews,
    modularBlocks,
    relatedProductsHeading,
  },
  datoCmsProductSitewide: {
    popularCategories,
    paperTypes,
    iconPoints,
    excessMessaging,
    rollWidth,
    rollHeight,
    fireRatings,
    installMethod,
    paperType,
    eco,
    accordionItems,
    modularBlocksOneSitewide,
    modularBlocksTwoSitewide,
    paperSamples,
    zoomTooltipMessaging,
  },
  mainCategory,
  relatedProducts,
  categoryProducts,
  bestSellingProducts,
  recentlyViewedProducts,
  datoCmsCroppingTool,
  location,
  version,
}) => {
  const {
    seo,
    title,
    options,
    descriptionHtml,
    descriptiveName,
    patternMatchMetafield,
    verticalPatternRepeatMetafield,
    wideCropMetafield,
    wideCropMaxMetafield,
  } = getShopifyLocaleFields(shopifyProduct, locale);
  const { setOverlayActive } = useContext(StoreContext);

  const isMural = checkIfMural(
    variants.find(variant => variant.title.includes('Premium')).sku
  );

  const [selectedVariant, setSelectedVariant] = useState(
    variants.find(variant => variant.title.includes('Premium'))
  );

  const [selectedSampleVariant, setSelectedSampleVariant] = useState(
    sampleVariants.find(variant => variant.title.includes('Premium'))
  );

  const [isPaperSamplesOpen, setPaperSamplesOpen] = useState(false);

  const relatedOrCategoryProducts =
    relatedProducts.length > 0
      ? relatedProducts
      : categoryProducts.length > 0
      ? categoryProducts
      : [];

  const otherProducts = [...bestSellingProducts, ...relatedOrCategoryProducts];

  const togglePaperSamplesOpen = () => {
    setOverlayActive(!isPaperSamplesOpen);
    setPaperSamplesOpen(!isPaperSamplesOpen);
  };

  return (
    <ProductContext.Provider
      value={{
        selectedVariant,
        setSelectedVariant,
        selectedSampleVariant,
        setSelectedSampleVariant,
        isPaperSamplesOpen,
        togglePaperSamplesOpen,
      }}
    >
      <Helmet
        script={[
          {
            type: 'application/ld+json',
            innerHTML: `{
                "@context": "https://schema.org/",
                "@type": "Product",
                "description": "${seo.description}",
                "sku": "${selectedVariant.sku}",
                ${
                  mainImages.length > 0
                    ? mainImages[0].url && `"image": "${mainImages[0].url}",`
                    : ''
                }
                "name": "${title}",
                ${
                  reviews.length > 0
                    ? `"review": [
                  ${reviews.map(
                    ({ author, score }) =>
                      `{
                      "@type": "Review",
                      "reviewRating": {
                        "@type": "Rating",
                        "ratingValue": "${score}"
                      },
                      "author": {
                        "@type": "Person",
                        "name": "${author}"
                      }
                    }`
                  )}
                ],`
                    : ''
                }
                ${
                  reviews.length > 0
                    ? `"aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": "${
                      reviews.reduce(
                        (accumulator, currentValue) =>
                          accumulator + currentValue.score,
                        0
                      ) / reviews.length
                    }",
                    "bestRating": "5",
                    "ratingCount": "${reviews.length}"
                  }`
                    : ''
                }
              }`,
          },
        ]}
      />
      <ProductDetail
        locationData={location}
        mainCategory={mainCategory}
        otherCategories={popularCategories}
        extraImages={{
          mainImages: mainImages,
          thumbnailImages: thumbnailImages,
        }}
        title={title}
        descriptiveName={descriptiveName.value}
        reviews={reviews}
        options={options}
        variants={variants}
        sampleVariants={sampleVariants}
        paperTypes={paperTypes}
        iconPoints={iconPoints}
        excessMessaging={excessMessaging}
        descriptionHtml={descriptionHtml}
        rollWidth={rollWidth}
        rollHeight={rollHeight}
        patternMatch={
          patternMatchMetafield && patternMatchMetafield.patternMatch
        }
        verticalPatternRepeat={
          verticalPatternRepeatMetafield &&
          verticalPatternRepeatMetafield.verticalPatternRepeat
        }
        fireRatings={fireRatings}
        installMethod={installMethod}
        paperType={paperType}
        eco={eco}
        accordionItems={accordionItems}
        zoomTooltipMessaging={zoomTooltipMessaging}
        datoCmsCroppingTool={datoCmsCroppingTool}
        maxCropImages={
          isMural && wideCropMaxMetafield && wideCropMaxMetafield.wideCropMax
        }
        enableWideCrop={
          isMural && wideCropMetafield && wideCropMetafield.wideCrop
        }
        locale={locale}
        version={version}
      />
      {isMural && <HowMuralsWork locale={locale} />}
      {recentlyViewedProducts.length > 0 && (
        <ProductCardSlider
          heading={translateString('product.recentlyViewedHeading', locale)}
          items={recentlyViewedProducts}
        />
      )}
      {reviews.length > 0 && (
        <ProductReviews product={title} items={reviews} locale={locale} />
      )}
      <ModularBlocks items={modularBlocks} locale={locale} />
      <ModularBlocks
        items={modularBlocksOneSitewide.filter(({ model: { apiKey } }) =>
          apiKey === 'testimonials_modular_block'
            ? reviews.length === 0
              ? true
              : false
            : true
        )}
        locale={locale}
      />
      <ProductListing
        heading={
          relatedProductsHeading ||
          translateString('product.relatedHeading', locale)
        }
        items={otherProducts}
        isProductPage={true}
      />
      <ModularBlocks items={modularBlocksTwoSitewide} locale={locale} />
      {paperSamples.length > 0 && (
        <ImageContent
          image={paperSamples[0].image}
          heading={paperSamples[0].heading}
          text={paperSamples[0].text}
          flip={paperSamples[0].flip}
          version={2}
          isPaperSamples={true}
          paperSamplesName={title}
        />
      )}
    </ProductContext.Provider>
  );
};

export default Product;
