import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  standardColours,
} from '../styles';
import { HtmlContent, Svg } from './ui';
import {
  formatPrice,
  getDispatchDate,
  getSingleLocaleFields,
  translateString,
} from '../utils';
import { StoreContext } from '../context/StoreContext';
import PaperTypesInfo from './PaperTypesInfo';
import CustomisationInfo from './CustomisationInfo';
import MockupInfo from './MockupInfo';
import googleReviewBadge from '../images/google-review-badge.inline.svg';
import orderIcon from '../images/order-icon.inline.svg';
import deliveryIcon from '../images/free-delivery.inline.svg';

const StyledProductInfo = styled.section`
  padding: 24px 0;

  ${minBreakpointQuery.mlarge`
    padding: 20px 0;
 `}
`;

const StyledHtml = styled(HtmlContent)`
  color: ${standardColours.darkestGrey};

  p {
    ${fontSize(12)};

    ${minBreakpointQuery.tsmall`
      ${fontSize(14)};
    `}
  }
`;

const StyledList = styled.div`
  margin-top: 30px;
`;

const StyledListHeading = styled.h3`
  color: ${brandColours.primary};
  font-weight: ${fontWeights.semibold};
  ${fontSize(14)}

  ${minBreakpointQuery.tsmall`
    ${fontSize(16)};
  `}
`;

const StyledListItems = styled.ul`
  margin-top: 12px;

  ${minBreakpointQuery.tsmall`
    margin-top: 16px;
  `}
`;

const StyledListItem = styled.li`
  margin: 10px 0;
  ${fontSize(12)}
  line-height: 1.5;

  ${minBreakpointQuery.tsmall`
    ${fontSize(14)};
  `}
`;

const StyledListItemIcon = styled(Svg)`
  margin-right: 10px;
  height: 16px;
  width: 16px;
  vertical-align: middle;

  path {
    fill: ${standardColours.black};
  }

  ${minBreakpointQuery.tsmall`
    height: 20px;
    width: 20px;
  `}
`;

const StyledListItemImage = styled.img`
  margin-right: 10px;
  height: 16px;
  width: 16px;

  ${minBreakpointQuery.tsmall`
    height: 20px;
    width: 20px;
  `}
`;

const StyledGoogleReview = styled(Svg)`
  margin-top: 24px;
  height: 22px;
  width: 174px;
`;

const StyledInfoButtons = styled.div`
  margin-top: 24px;
  display: grid;
  row-gap: 12px;
`;

const StyledInfoButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: ${brandColours.senary};
  padding: 12px;
  ${fontSize(12)}
  font-weight: ${fontWeights.medium};
  width: 100%;

  ${minBreakpointQuery.tsmall`
    ${fontSize(14)}
    padding: 14px 16px;
  `}
`;

const StyledQuestionIcon = styled.span`
  border: 2px solid ${standardColours.black};
  border-radius: 50%;
  padding: 2px 7px;
  font-weight: ${fontWeights.semibold};
  ${fontSize(13)}
`;

const StyledProductSpecs = styled(HtmlContent)`
  margin-top: 24px;

  ul {
    li {
      padding-left: 0;
      ${fontSize(12)}

      ${minBreakpointQuery.tsmall`
        ${fontSize(14)};
      `}

      &::before {
        content: none;
      }
    }
  }
`;

const ProductInfoV2 = ({
  descriptionHtml,
  iconPoints,
  paperTypes,
  sku,
  rollWidth,
  rollHeight,
  patternMatch,
  verticalPatternRepeat,
  installMethod,
  leadTimeDays,
  leadTimeExcludedDates,
  freeDeliveryThresholds,
  isMural,
  locale,
}) => {
  const { setOverlayActive, currency } = useContext(StoreContext);

  const [isPaperTypesInfoOpen, setPaperTypesInfoOpen] = useState();
  const [isCustomisationInfoOpen, setCustomisationInfoOpen] = useState();
  const [isMockupInfoOpen, setMockupInfoOpen] = useState();

  const dispatchDate = getDispatchDate(leadTimeExcludedDates, leadTimeDays);
  const freeDeliveryThresholdsSitewide = getSingleLocaleFields(
    freeDeliveryThresholds,
    locale
  );

  const togglePaperTypesInfoOpen = () => {
    setOverlayActive(!isPaperTypesInfoOpen);
    setPaperTypesInfoOpen(!isPaperTypesInfoOpen);
  };

  const toggleCustomisationInfoOpen = () => {
    setOverlayActive(!isCustomisationInfoOpen);
    setCustomisationInfoOpen(!isCustomisationInfoOpen);
  };

  const toggleMockupInfoOpen = () => {
    setOverlayActive(!isMockupInfoOpen);
    setMockupInfoOpen(!isMockupInfoOpen);
  };

  const getPatternMatch = patternMatch => {
    const patternMatchInt = parseInt(patternMatch);

    if (patternMatchInt === 1) {
      return 'No Match';
    } else if (patternMatchInt === 2) {
      return 'Straight Match';
    } else if (patternMatchInt === 3) {
      return 'Drop Match';
    }
  };

  const verticalPatternRepeatParsed = verticalPatternRepeat
    ? JSON.parse(verticalPatternRepeat)
    : { value: 0, unit: 'CENTIMETERS' };

  const productSpec = [
    `<strong>${translateString('productInfo.sku', locale)}:</strong> ${sku}`,
  ];

  if (!isMural) {
    productSpec.push(
      `<strong>${translateString('productInfo.rollWidth', locale)}:</strong> ${
        rollWidth / 10
      }cm`,
      `<strong>${translateString('productInfo.rollHeight', locale)}:</strong> ${
        rollHeight / 1000
      }m`,
      `<strong>Pattern Match:</strong> ${
        patternMatch ? getPatternMatch(patternMatch) : ''
      }`,
      `<strong>${translateString(
        'productInfo.verticalPatternRepeat',
        locale
      )}:</strong> ${verticalPatternRepeatParsed.value}${
        verticalPatternRepeatParsed.unit === 'CENTIMETERS'
          ? 'cm'
          : verticalPatternRepeatParsed.unit
      }`
    );
  }

  productSpec.push(
    `<strong>${translateString(
      'productInfo.installMethod',
      locale
    )}:</strong> ${installMethod}`
  );

  return (
    <StyledProductInfo>
      <StyledHtml html={descriptionHtml} />
      {iconPoints.length > 0 && (
        <StyledList>
          <StyledListHeading>
            {translateString('product.productInfoHeading', locale)}
          </StyledListHeading>
          <StyledListItems>
            <StyledListItem>
              <StyledListItemIcon image={orderIcon} />
              {`${translateString(
                'product.dispatchNoticeShort',
                locale
              )} ${dispatchDate}`}
            </StyledListItem>
            <StyledListItem>
              <StyledListItemIcon image={deliveryIcon} />
              {`${translateString('product.freeDeliveryInfo', locale).replace(
                'delivery',
                () => (currency === 'USD' ? 'shipping' : 'delivery')
              )} ${formatPrice({
                amount: freeDeliveryThresholdsSitewide[currency.toLowerCase()],
                currencyCode: currency,
              })}`}
            </StyledListItem>
            {iconPoints.map(({ icon, text }) => (
              <StyledListItem>
                <StyledListItemImage
                  src={icon.url}
                  alt={icon.alt}
                  loading="lazy"
                />
                {text}
              </StyledListItem>
            ))}
          </StyledListItems>
        </StyledList>
      )}
      <StyledGoogleReview image={googleReviewBadge} />
      <StyledInfoButtons>
        <StyledInfoButton onClick={togglePaperTypesInfoOpen}>
          <span>{translateString('product.learnPaperTypes', locale)}</span>
          <StyledQuestionIcon>?</StyledQuestionIcon>
        </StyledInfoButton>
        <StyledInfoButton onClick={toggleCustomisationInfoOpen}>
          <span>{translateString('product.customisation', locale)}</span>
          <StyledQuestionIcon>?</StyledQuestionIcon>
        </StyledInfoButton>
        <StyledInfoButton onClick={toggleMockupInfoOpen}>
          <span>{translateString('product.mockup', locale)}</span>
          <StyledQuestionIcon>?</StyledQuestionIcon>
        </StyledInfoButton>
      </StyledInfoButtons>
      <PaperTypesInfo
        paperTypes={paperTypes}
        isPaperTypesInfoOpen={isPaperTypesInfoOpen}
        togglePaperTypesInfoOpen={togglePaperTypesInfoOpen}
      />
      <CustomisationInfo
        locale={locale}
        isCustomisationInfoOpen={isCustomisationInfoOpen}
        toggleCustomisationInfoOpen={toggleCustomisationInfoOpen}
      />
      <MockupInfo
        locale={locale}
        isMockupInfoOpen={isMockupInfoOpen}
        toggleMockupInfoOpen={toggleMockupInfoOpen}
      />
      <StyledProductSpecs
        html={`
          <ul>
            <li>${productSpec.join('</li><li>')}</li>
          </ul>`}
      />
    </StyledProductInfo>
  );
};

export default ProductInfoV2;
