import React, { useContext, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  brandColours,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  standardColours,
  zIndexLayers,
} from '../styles';
import { Button, Container } from './ui';
import { checkIfMural, translateString, useVariantPrice } from '../utils';
import Price from './Price';
import { StoreContext } from '../context/StoreContext';
import { ProductContext } from './Product';
import ProductFormV2 from './ProductFormV2';
import ProductInfoV2 from './ProductInfoV2';
import RollCalculator from './RollCalculator';
import PaperSamples from './PaperSamples';
import CroppingTool from './CroppingTool';
import StarReview from './StarReview';

const StyledProductSummary = styled.section`
  padding-top: 24px;
  padding-bottom: 80px;

  ${minBreakpointQuery.mlarge`
    padding-top: 50px;
    padding-bottom: 110px;
  `}
`;

const StyledProductTop = styled.div`
  ${minBreakpointQuery.mlarge`
    display: grid;
    grid-template-columns: 1fr 440px;
    column-gap: 40px;
  `}
`;

const StyledProductIntro = styled.div``;

const StyledProductSidebar = styled.div`
  ${minBreakpointQuery.mlarge`
    grid-row: 1 / 4;
    grid-column: 2;
    margin-top: -250px;
    z-index: ${zIndexLayers.first};
  `}
`;

const StyledNamePrice = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: ${brandColours.primary};
`;

const StyledFriendlyName = styled.p`
  ${fontSize(22)};
  font-weight: ${fontWeights.semibold};

  ${minBreakpointQuery.tsmall`
    ${fontSize(26)};
  `}
`;

const StyledPrice = styled(Price)`
  ${fontSize(18)};

  ${minBreakpointQuery.tsmall`
    ${fontSize(22)};
  `}
`;

const StyledDescriptiveNameReviews = styled.div`
  ${minBreakpointQuery.mlarge`
    display: flex;
    gap: 8px;
    align-items: center;
  `}
`;

const StyledDescriptiveName = styled.h1`
  margin-top: 5px;
  ${fontSize(14)};
  font-weight: ${fontWeights.medium};
`;

const StyledReviews = styled(StarReview)`
  margin-top: 5px;
`;

const StyledSamples = styled.div`
  margin-top: 20px;
  background-color: ${standardColours.white};
  padding: 16px;
  box-shadow: 0px 13px 84px 0px ${standardColours.transparentBlack(0.08)};

  ${minBreakpointQuery.tsmall`
    padding: 24px;
  `}
`;

const StyledSamplseHeading = styled.span`
  font-weight: ${fontWeights.semibold};
  ${fontSize(14)};
  color: ${brandColours.primary};
`;

const StyledSamplesList = styled.ul`
  margin-top: 12px;
  padding-left: 16px;
`;

const StyledSamplesListItem = styled.li`
  list-style-type: initial;
  margin-top: 6px;
  ${fontSize(12)}
  line-height: 1.5;

  ${minBreakpointQuery.tsmall`
    ${fontSize(14)};
  `}
`;

const StyledSamplesButton = styled(Button)`
  margin-top: 14px;
  width: 100%;
  background-color: ${brandColours.primary};
  border-color: ${brandColours.primary};
  padding: 10px 22px;
  ${fontSize(12)}

  ${minBreakpointQuery.tsmall`
    ${fontSize(14)};
    margin-top: 18px;
  `}

  ${minBreakpointQuery.tsmall`
    margin-top: 24px;
  `}
`;

const ProductSummaryV2 = ({
  title,
  descriptiveName,
  descriptionHtml,
  reviews,
  options,
  variants,
  sampleVariants,
  selectedVariantImages,
  getVariant,
  paperTypes,
  rollWidth,
  rollHeight,
  patternMatch,
  verticalPatternRepeat,
  installMethod,
  excessMessaging,
  iconPoints,
  datoCmsCroppingTool,
  maxCropImages,
  enableWideCrop,
  locale,
}) => {
  const {
    datoCmsProductSitewide: {
      minGbp,
      minUsd,
      minCad,
      minAud,
      minNzd,
      minEur,
      minHkd,
      minChf,
      minAed,
      minSar,
      leadTimeDays,
      leadTimeExcludedDates,
    },
    allDatoCmsFreeDeliveryThresholdsSitewide,
  } = useStaticQuery(graphql`
    query ProductSummaryV2Query {
      datoCmsProductSitewide {
        minGbp
        minUsd
        minCad
        minAud
        minNzd
        minEur
        minHkd
        minChf
        minAed
        minSar
        leadTimeDays
        leadTimeExcludedDates {
          excludedDate
        }
      }
      allDatoCmsFreeDeliveryThresholdsSitewide {
        nodes {
          locale
          sitewideNotice
          gbp
          usd
          cad
          aud
          nzd
          eur
          hkd
          chf
          aed
          sar
        }
      }
    }
  `);

  const { setOverlayActive, currency, currencyLoaded } =
    useContext(StoreContext);

  const {
    selectedVariant,
    setSelectedVariant,
    selectedSampleVariant,
    setSelectedSampleVariant,
    togglePaperSamplesOpen,
  } = useContext(ProductContext);

  const isMural = checkIfMural(selectedVariant.sku);

  const variantPrice = useVariantPrice(selectedVariant);

  const [total, setTotal] = useState(variantPrice.amount);
  const [inputFocus, setInputFocus] = useState('crop-width');
  const [cropImage, setCropImage] = useState('');
  const [cropWidth, setCropWidth] = useState();
  const [cropHeight, setCropHeight] = useState();
  const [croppingMode, setCroppingMode] = useState(false);
  const [unit, setUnit] = useState();
  const [isRollCalculatorOpen, setRollCalculatorOpen] = useState();

  const minOrderValues = {
    GBP: minGbp,
    USD: minUsd,
    CAD: minCad,
    AUD: minAud,
    NZD: minNzd,
    EUR: minEur,
    HKD: minHkd,
    CHF: minChf,
    AED: minAed,
    SAR: minSar,
  };

  useEffect(() => {
    currencyLoaded && setUnit(currency === 'USD' ? 'in' : 'cm');
  }, [currencyLoaded, currency]);

  useEffect(() => {
    if (selectedVariantImages && selectedVariantImages.mainImages.length > 0) {
      // See if the image has a url or src

      const image = selectedVariantImages.mainImages[1]
        ? selectedVariantImages.mainImages[1]
        : selectedVariantImages.mainImages[0];

      setCropImage({
        url: image.url
          ? image.url.replace('.jpg', '.jpg?w=1680&')
          : image.src.replace('.jpg', '_1680x.jpg'),
        height: image.gatsbyImageData.height,
        width: image.gatsbyImageData.width,
      });
    }
  }, [selectedVariantImages]);

  const handleChange = (name, value, i) => {
    const variant = getVariant(variants, {
      ...selectedVariant.selectedOptions,
      [i]: {
        name,
        value,
      },
    });
    setSelectedVariant(variant);

    const sampleVariant = getVariant(sampleVariants, {
      ...selectedSampleVariant.selectedOptions,
      [i]: {
        name,
        value,
      },
    });

    // Don't allow the Self Adhesive sample to be chosen
    if (
      !sampleVariant.title.includes('Self Adhesive') &&
      !sampleVariant.title.includes('Auto-adhésif')
    ) {
      setSelectedSampleVariant(sampleVariant);
    }
  };

  const toggleRollCalculatorOpen = () => {
    setOverlayActive(!isRollCalculatorOpen);
    setRollCalculatorOpen(!isRollCalculatorOpen);
  };

  const toggleCroppingModeOpen = () => {
    !croppingMode && window.clarity && window.clarity('stop');
    setOverlayActive(!croppingMode);
    setCroppingMode(!croppingMode);
  };

  return (
    <StyledProductSummary>
      <Container narrow={true}>
        <StyledProductTop>
          <StyledProductIntro>
            <StyledNamePrice>
              <StyledFriendlyName>{title}</StyledFriendlyName>
              {currencyLoaded && (
                <StyledPrice variant={selectedVariant} locale={locale} />
              )}
            </StyledNamePrice>
            <StyledDescriptiveNameReviews>
              <StyledDescriptiveName>{descriptiveName}</StyledDescriptiveName>
              {reviews.length > 0 && (
                <StyledReviews
                  value={
                    reviews.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.score,
                      0
                    ) / reviews.length
                  }
                  total={reviews.length}
                />
              )}
            </StyledDescriptiveNameReviews>
          </StyledProductIntro>
          <StyledProductSidebar>
            <ProductFormV2
              options={options}
              getVariant={getVariant}
              variants={variants}
              minOrderValues={minOrderValues}
              cropWidth={cropWidth}
              cropHeight={cropHeight}
              selectedUnit={unit}
              excessMessaging={excessMessaging}
              selectedVariant={selectedVariant}
              paperTypes={paperTypes}
              total={total}
              setTotal={setTotal}
              setCropWidth={setCropWidth}
              setCropHeight={setCropHeight}
              setSelectedUnit={setUnit}
              setInputFocus={setInputFocus}
              toggleCroppingModeOpen={toggleCroppingModeOpen}
              toggleRollCalculatorOpen={toggleRollCalculatorOpen}
              setOverlayActive={setOverlayActive}
              setCroppingMode={setCroppingMode}
              handleChange={handleChange}
              isMural={isMural}
              locale={locale}
            />
            <StyledSamples>
              <StyledSamplseHeading>
                {translateString('product.orderSamples', locale)} "{title}"
              </StyledSamplseHeading>
              <StyledSamplesList>
                {translateString('product.sampleListItems', locale).map(
                  (item, id) => (
                    <StyledSamplesListItem key={id}>
                      {item}
                    </StyledSamplesListItem>
                  )
                )}
              </StyledSamplesList>
              <StyledSamplesButton onClick={togglePaperSamplesOpen}>
                {translateString('product.orderSamplesButton', locale)}
              </StyledSamplesButton>
            </StyledSamples>
          </StyledProductSidebar>
          <ProductInfoV2
            descriptionHtml={descriptionHtml}
            iconPoints={iconPoints}
            paperTypes={paperTypes}
            sku={selectedVariant.sku}
            rollWidth={rollWidth}
            rollHeight={rollHeight}
            patternMatch={patternMatch}
            verticalPatternRepeat={verticalPatternRepeat}
            installMethod={installMethod}
            leadTimeDays={leadTimeDays}
            leadTimeExcludedDates={leadTimeExcludedDates}
            freeDeliveryThresholds={allDatoCmsFreeDeliveryThresholdsSitewide}
            isMural={isMural}
            locale={locale}
          />
          {!isMural ? (
            <RollCalculator
              rollHeight={rollHeight}
              rollWidth={rollWidth}
              patternLength={verticalPatternRepeat}
              locale={locale}
              isRollCalculatorOpen={isRollCalculatorOpen}
              toggleRollCalculatorOpen={toggleRollCalculatorOpen}
            />
          ) : (
            <CroppingTool
              image={cropImage}
              croppingMode={croppingMode}
              toggleCroppingModeOpen={toggleCroppingModeOpen}
              options={options}
              handleChange={handleChange}
              variants={variants}
              getVariant={getVariant}
              selectedVariant={selectedVariant}
              paperTypes={paperTypes}
              selectedUnit={unit}
              cropWidth={cropWidth}
              cropHeight={cropHeight}
              inputFocus={inputFocus}
              minOrderValues={minOrderValues}
              total={total}
              setTotal={setTotal}
              setCropWidth={setCropWidth}
              setCropHeight={setCropHeight}
              setSelectedUnit={setUnit}
              datoCmsCroppingTool={datoCmsCroppingTool}
              maxCropImages={maxCropImages}
              enableWideCrop={enableWideCrop}
              locale={locale}
              excessMessaging={excessMessaging}
            />
          )}
          <PaperSamples
            options={options}
            handleChange={handleChange}
            variants={variants}
            getVariant={getVariant}
            selectedVariant={selectedVariant}
            paperTypes={paperTypes}
            locale={locale}
          />
        </StyledProductTop>
      </Container>
    </StyledProductSummary>
  );
};

export default ProductSummaryV2;
